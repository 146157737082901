import React, { useState, useEffect } from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import { userHasAccess } from "@impact-theory/app-auth";

const Layout = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess();
      setLoggedIn(result);
      setLoading(false);
    }
    run();
  }, []);

  if (!loggedIn) return <Heading marginBottom="2rem">No Permission</Heading>;

  return (
    <>
      <Flex width="100%" alignItems="center" flexDirection="column">
        <Flex padding="1rem"></Flex>
        <Box
          borderRadius="0.5rem"
          border="1px solid black"
          marginBottom="2rem"
          width="90%"
        >
          <Flex flexDirection="column" padding="1rem">
            {children}
          </Flex>
        </Box>
        <Box></Box>
      </Flex>
    </>
  );
};

export default Layout;
