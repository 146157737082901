import lifecycles from "./impact-theory-app-avatar-arena-admin";

export const avatarAdminSubMenu = [
  {
    label: "View Tournaments",
    subLabel: "View All Tournaments",
    href: "/avatar-arena/tournaments",
  },
  {
    label: "Create Tournament",
    subLabel: "Create a new tournament",
    href: "/avatar-arena/create-tournament",
  },
];

export const { bootstrap, mount, unmount } = lifecycles;
