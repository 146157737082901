import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AvatarArena from "./pages/AvatarArena";
import TournamentList from "./pages/TournamentList";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/avatar-arena/create-tournament"
            element={<AvatarArena />}
          />
          <Route
            path="/avatar-arena/tournaments"
            element={<TournamentList />}
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
