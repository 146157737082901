import { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Input,
  Tr,
  Th,
  Td,
  ModalFooter,
  TableContainer,
  IconButton,
  useToast,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon, EditIcon } from "@chakra-ui/icons";
import { Heading } from "@chakra-ui/react";
import { api } from "../services/api";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import Layout from "../components/Layout";
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const TournamentList = () => {
  const toast = useToast();
  const [tournaments, setTournaments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tournamentUpdating, setTournamentUpdating] = useState(false);
  const [tournamentExportLoading, setTournamentExportLoading] = useState(false);
  const [selectedTournamentId, setSelectedTournamentId] = useState(undefined);
  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [editImageUrl, setEditImageUrl] = useState("");
  const [editName, setEditName] = useState("");
  const [editTheme, setEditTheme] = useState("");
  const [editStartDate, setEditStartDate] = useState("");
  const [editEndDate, setEditEndDate] = useState("");
  const getTournaments = async () => {
    setLoading(true);
    try {
      const response = await api.getTournaments(page, size);
      setTotalCount(response.data.data.count);
      setTournaments(response.data.data.list);
    } catch (e) {
      setTotalCount(0);
      setTournaments([]);
    }
    setLoading(false);
  };

  const handleClickActionButton = async (tournamentId, status) => {
    setTournamentUpdating(true);
    setSelectedTournamentId(tournamentId);
    try {
      const response = status
        ? await api.stopTournament(tournamentId)
        : await api.startTournament(tournamentId);
      let tempTournaments = tournaments;
      tempTournaments = [
        ...tempTournaments.map((tournament) => {
          let tempTournament = tournament;
          if (tournament.tournamentId === tournamentId) {
            tempTournament = { ...tempTournament, active: !status };
          }
          return tempTournament;
        }),
      ];
      setTournaments(tempTournaments);
      setSelectedTournamentId(undefined);
      setTournamentUpdating(false);
    } catch {
      setSelectedTournamentId(undefined);
      setTournamentUpdating(false);
    }
  };

  const handleExport = async (tournamentId) => {
    setTournamentExportLoading(true);
    try {
      const res = await api.raffleExport(tournamentId);
      const blob = new Blob([res.data]);
      const fileName = res.headers["content-disposition"]
        ? res.headers["content-disposition"].split("filename=")[1]
        : "raffle.csv";
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
        this.loading = false;
      } else {
        navigator.msSaveBlob(blob, fileName);
        this.loading = false;
      }
      setTournamentExportLoading(false);
    } catch {
      setTournamentExportLoading(false);
    }
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / size);
  }, [totalCount, size]);

  const startIndex = useMemo(() => {
    return (page - 1) * size + 1;
  }, [page, size]);

  const lastIndex = useMemo(() => {
    if (page * size > totalCount) {
      return totalCount;
    }
    return page * size;
  }, [page, size, totalCount]);

  const onChangePageSize = (e) => {
    setSize(e.target.value);
    setPage(1);
  };

  const onPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const editSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.updateTournament({
        tournamentId: editId,
        name: editName,
        theme: editTheme,
        imageUrl: editImageUrl,
        startDate: editStartDate,
        endDate: editEndDate,
      });
      toast({
        title: "success",
        status: "success",
        duration: 2000,
      });
      setEditOpen(false);
      getTournaments();
    } catch (e) {
      toast({
        title: e.response.data.message,
        status: "error",
        duration: 2000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getTournaments();
  }, [page, size]);

  return (
    <Layout>
      <Heading marginBottom="2rem">Avatar Arena Tournaments</Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Theme</Th>
              <Th>Action</Th>
              <Th>Active</Th>
              <Th>Export</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Votes</Th>
              <Th>Winner</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <Tr>
                <Td colSpan={7} className="text-center">
                  Loading...
                </Td>
              </Tr>
            )}
            {tournaments.length === 0 && !loading && (
              <Tr>
                <Td colSpan={7} className="text-center">
                  No Tournaments
                </Td>
              </Tr>
            )}
            {!loading &&
              tournaments.map((tournament) => {
                return (
                  <Tr key={tournament.tournamentId}>
                    <Td>{tournament.name || ""}</Td>
                    <Td>{tournament.theme}</Td>
                    <Td>
                      <Button
                        colorScheme={tournament.active ? "red" : "blue"}
                        disabled={tournamentUpdating}
                        isLoading={
                          selectedTournamentId === tournament.tournamentId &&
                          tournamentUpdating
                        }
                        onClick={() =>
                          handleClickActionButton(
                            tournament.tournamentId,
                            tournament.active
                          )
                        }
                      >
                        {tournament.active ? "Stop" : "Start"}
                      </Button>
                    </Td>
                    <Td>
                      <Button
                        colorScheme={"blue"}
                        disabled={tournamentExportLoading}
                        isLoading={tournamentExportLoading}
                        onClick={() => handleExport(tournament.tournamentId)}
                      >
                        Export Entries
                      </Button>
                    </Td>
                    <Td>{tournament.active ? "True" : "False"}</Td>
                    <Td>
                      {dayjs(tournament.startDate).format(
                        "MMM DD YYYY h:mm a. z"
                      )}
                    </Td>
                    <Td>
                      {dayjs(tournament.endDate).format(
                        "MMM DD YYYY h:mm a. z"
                      )}
                    </Td>
                    <Td>{tournament.votes || 0}</Td>
                    <Td>{tournament.winner || ""}</Td>
                    <Td>
                      {!tournament.active && (
                        <IconButton
                          onClick={() => {
                            setEditName(tournament.name);
                            setEditId(tournament.tournamentId);
                            setEditImageUrl(tournament.imageUrl);
                            setEditTheme(tournament.theme);
                            setEditStartDate(
                              tournament.startDate
                                ? tournament.startDate.split("T")[0]
                                : tournament.startDate
                            );
                            setEditEndDate(
                              tournament.endDate
                                ? tournament.endDate.split("T")[0]
                                : tournament.endDate
                            );
                            setEditOpen(true);
                          }}
                          aria-label="Edit database"
                          icon={<EditIcon />}
                        />
                      )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <p>
          {startIndex} - {lastIndex} of {totalCount}
        </p>
        <Box display="flex" alignItems="center">
          <Box w="80px">
            <Select onChange={onChangePageSize} value={size}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </Select>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginLeft={2}
          >
            <Button
              colorScheme="blue"
              disabled={page <= 1}
              onClick={onPreviousPage}
              leftIcon={<ArrowLeftIcon />}
              marginRight={2}
            >
              Prev
            </Button>
            <Button
              colorScheme="blue"
              rightIcon={<ArrowRightIcon />}
              disabled={page >= totalPages}
              onClick={onNextPage}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={editOpen} onClose={() => setEditOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Name"
              marginBottom={"16px"}
              value={editName}
              onChange={(event) => setEditName(event.target.value)}
            />
            <Input
              placeholder="Theme"
              marginBottom={"16px"}
              value={editTheme}
              onChange={(event) => setEditTheme(event.target.value)}
            />
            <Input
              placeholder="Start date"
              marginBottom={"16px"}
              type="date"
              value={editStartDate}
              onChange={(event) => setEditStartDate(event.target.value)}
            />
            <Input
              placeholder="End date"
              marginBottom={"16px"}
              type="date"
              value={editEndDate}
              onChange={(event) => setEditEndDate(event.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => setEditOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={() => editSubmit()}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default TournamentList;
