import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

import { api } from "../services/api";

const AddTournament = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    theme: "",
    startDate: "",
    endDate: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await api.createTournament(formData);
      if (res) {
        toast({
          title: "Tournament created.",

          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Tabs isFitted variant="enclosed">
      <TabList mb="1em">
        <Tab>Create New Tournament</Tab>
        <Tab>Edit New Tournament</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Tournament Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <FormLabel>Tournament Theme</FormLabel>
              <Input
                type="text"
                name="theme"
                value={formData.theme}
                onChange={handleChange}
              />
              <FormLabel>Start date</FormLabel>
              <Input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
              />
              <FormLabel>End date</FormLabel>
              <Input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
              />
              <Button mt={4} colorScheme="blue" type="submit">
                Save
              </Button>
            </FormControl>
          </form>
        </TabPanel>
        <TabPanel>
          <FormControl></FormControl>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AddTournament;
